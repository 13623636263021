<template>
	<v-row
		style="padding-top:70px; height:100%"
		no-gutter
		dense
		class="password R-Layout d-flex flex-column"
	>
		<v-row>
			<v-col cols="12" class="col-sm-8 col-md-8">
				<v-form v-model="valid" ref="form" lazy-validation>
					<v-card tag="div" elevation="0" outlined color="#F7F9FC">
						<p class="my-heading ma-0" style="padding-bottom:48px">
							Password
						</p>

						<v-row class="pb-16" dense>
							<v-col cols="12" class="pt-0">
								<label>Email Address</label>
							</v-col>
							<v-col cols="12" class="pb-0">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									readonly
									color="#CACEDA"
									type="text"
									v-model="password_data.email"
									placeholder="Email address"
									:rules="emailRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="pb-16" dense>
							<v-col cols="12" class="pt-0">
								<label>New Password</label>
							</v-col>
							<v-col cols="12" class="pb-0">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="password"
									v-model="password_data.password"
									placeholder="New password"
									:rules="passwordRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="pb-16" dense>
							<v-col cols="12" class="pt-0">
								<label>Confirm New Password</label>
							</v-col>
							<v-col cols="12" class="pb-0">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="password"
									v-model="password_data.con_password"
									placeholder="Confirm new password"
									:rules="conpasswordRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="pb-16" dense>
							<v-col cols class="pb-0">
								<div class="d-flex align-center">
									<app-my-checkbox
										v-model="trems_data"
									></app-my-checkbox>
									<p class="T-P pt-2">
										I agree to the
										<font
											class="links"
											v-on:click="
												$refs.readprivacy.readPrivacy()
											"
											>Privacy Policy</font
										>,
										<font
											class="links"
											v-on:click="
												$refs.readterms.readTerms()
											"
											>Terms of Service</font
										>,
										<font
											class="links"
											v-on:click="
												$refs.readreturnpolicy.readReturnPolicy()
											"
											>Return Policy</font
										>
										and
										<font
											class="links"
											v-on:click="
												$refs.readdisclaimer.readDisclaimer()
											"
											>Disclaimer</font
										>.
									</p>
								</div>
							</v-col>
						</v-row>

						<v-row
							dense
							class="align-center justify-center p-0 custom-error"
							:style="{ display: ShowMessage }"
						>
							<v-col cols="12">{{ this.error_message }}</v-col>
						</v-row>
						<v-row
							dense
							v-if="$store.state.auth.error != ''"
							class="align-center justify-center p-0 custom-error"
						>
							<v-col cols="12">{{
								this.$store.state.auth.error
							}}</v-col>
						</v-row>
					</v-card>
				</v-form>
			</v-col>
		</v-row>

		<v-row class="align-self-sm-end next-btn">
			<v-col class="d-flex align-end justify-sm-end pb-0" cols="12" sm="2">
				<v-btn
					class="my-p-btn my_elevation"
					id="my_elevation"
					depressed
					@click="Password"
					:disabled="!valid"
					>Next</v-btn
				>
			</v-col>
		</v-row>

		<app-privacy ref="readprivacy"></app-privacy>
		<app-terms ref="readterms"></app-terms>
		<app-return-policy ref="readreturnpolicy"></app-return-policy>
		<app-disclaimer ref="readdisclaimer"></app-disclaimer>
	</v-row>
</template>

<script>
const Privacy = () => import("./Privacy");
const Terms = () => import("./Terms");
const ReturnPolicy = () => import("./ReturnPolicy");
const Disclaimer = () => import("./Disclaimer");
import { setUser, userRegister } from "../../services/registerservice";
import Cookies from "js-cookie";
const Checkbox = () => import("../../mycomponents/Checkbox");

export default {
	components: {
		AppMyCheckbox: Checkbox,
		AppPrivacy: Privacy,
		AppTerms: Terms,
		AppReturnPolicy: ReturnPolicy,
		AppDisclaimer: Disclaimer,
	},

	data() {
		return {
			password_data: {
				user_id: "",
				email: "",
				password: "",
				con_password: "",
			},
			trems_data: {
				check: false,
				name: "trem",
				label: "",
			},
			show: true,
			valid: true,
			emailRules: [
				(v) => !!v || "E-mail is required",
				(v) => /.+@.+/.test(v) || "E-mail must be valid",
			],
			passwordRules: [
				(v) => !!v || "New password is required",
				(v) =>
					(v && v.length >= 8) ||
					"Password must be greater than 8 characters",
			],
			conpasswordRules: [
				(v) => !!v || "Confirm new password is required",
				(v) =>
					v == this.password_data.password ||
					"New password must match",
			],
			error_message: "",
			show_message: "none",
		};
	},

	beforeRouteLeave(to, from, next) {
		this.storeVuex();
		next();
	},

	async created() {
		if (this.$store.state.register.register_user.user_id === "") {
			await setUser();
			this.myPassword();
		} else {
			this.myPassword();
		}
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		},
		ShowMessage() {
			return (this.show_message = !this.error_message ? "none" : "block");
		},
	},

	methods: {
		myPassword() {
			let data = JSON.parse(
				JSON.stringify(this.$store.state.register.register_user)
			);

			this.password_data.user_id = data.user_id;
			this.password_data.email = data.email;
		},

		storeVuex() {
			this.$store.state.register.register_user.email = this.password_data.email;
		},

		changeTab(tab_name) {
			this.errorMessages = "";
			this.$store.commit("errorMessage", "");
			this.$store.commit("registerTab", tab_name);
		},

		async Password() {
			if (this.$refs.form.validate()) {
				if (this.trems_data.check === false) {
					this.error_message = "Please accept Trems and Policy";
				} else {
					this.error_message = "";
					this.$store.commit("errorMessage", "");

					let data = await userRegister(
						this.password_data.user_id,
						this.password_data
					);
					this.storeVuex();
					if (data.data) {
						this.$store.commit("passwordcomplete");
						this.changeTab("account");
					}
				}
			}
		},
	},
};
</script>

<style lang="scss">
.pb-16 {
	padding-bottom: 16px !important;
}

.v-input--selection-controls__input > .v-icon {
	font-size: 32px !important;
}

.password .T-P {
	font-family: Inter-Regular;
	font-size: 13px;
	color: #7587a3;

	.links {
		font-family: Inter-Regular;
		font-size: 13px;
		color: #387cfe;
		line-height: 19px;
	}
}
</style>
